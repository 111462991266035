import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view';
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'

import axios from 'axios';


// 设置 axios 默认的 base URL
// axios.defaults.baseURL = '';

// 请求拦截器：在请求发出前做一些处理
axios.interceptors.request.use(config => {
  console.log(config.url);
  if (config.url == '/api/cyyOrder/dataOrderMonth' || config.url == '/api/cyyOrder/dataOrder' || config.url == '/api/cyyOrder/dataAfterSaleStatistics') {
    config.headers['kunLunXuToken'] = localStorage.getItem('kunLunXuToken');
  } else {

    config.headers['Authorization'] = localStorage.getItem('key');
  }
  // 可以在这里添加认证 token 等    
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截器：处理响应数据
axios.interceptors.response.use(response => {
  try {
    return new Promise((resolve, reject) => {
      resolve(response.data);
    })
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}, error => {
  // 处理错误，例如：显示全局错误消息
  console.error('API request error:', error);
  return Promise.reject(error);
});




Vue.use(ElementUI);
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
Vue.config.productionTip = false;

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
